import React, { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro'
import { gqlErrorHandler, chkImageUrl, headerShadow } from './Library'
import Popup from './Popup';

const mainBannerGql = loader('../gql/main_banner.gql')
const Event = () => {
	useEffect(() => {
		headerShadow()
	})
	const values = { gid: process.env.REACT_APP_SERVICE_GID, }
	const { loading, error, data } = useQuery(mainBannerGql, { variables: values });
	if (loading) return null;
	if (error) { gqlErrorHandler(error); return null; }

	const shareEvent = (e) => {
		e.preventDefault()
		/* global AppScheme */
		typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://share?text=${encodeURIComponent(document.title)} https://pang.so/justorder`);
	}

	return (
		<div id="reWrap">
			{/* 상단 */}
			<div id="header" className="subHeader">
				<div className="header">
					<a href="#this" onClick={(e) => {e.preventDefault(); window.history.go(-1);}} className="txtHide btnHback">뒤로가기</a>
					<h1>진행중인 이벤트</h1>
					<a href="#this" className="txtHide btnHshare" onClick={shareEvent}>공유하기</a>
				</div>
			</div>

			{/* 컨텐츠 */}
			<div id="container">
				<div id="contents" className="subCnts">
					<div className="eventList">
						<ul>
							{data.gongBanners.edges.map((item, k) => (
								<li key={k}><Popup href={item.node.url} type={item.node.target}><img src={chkImageUrl(item.node.imgUrl)} alt="이미지" /></Popup></li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Event
