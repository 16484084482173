import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import $ from 'jquery'

export default function Filter(props) {
	useEffect(() => {
		new Swiper('.optionFilter .swiper-container2', {
			slidesPerView: 'auto',
		})

		if (_.isNull(openedFilter)) {
			$('body').css({ 'overflow': 'initial', 'touch-action': 'initial' });
		} else {
			$('body').css({ 'overflow': 'hidden', 'touch-action': 'none' });
		}
	})

	const sortyRef = useRef(null)
	const minAmountRef = useRef(null)
	const deliveryFeeRef = useRef(null)
	const getMethodRef = useRef(null)
	const payMethodRef = useRef(null)
	const hasCouponRef = useRef(null)

	const initFilterCopyRef = useRef(null) // 초기화값저장

	const location = useLocation()

	// 필터값 세팅
	const [filter, setFilter] = useState(() => {
		const objValueToTrue = (o) => {
			let obj = {}
			for (const key of Object.keys(o)) {
				obj[key] = true
			}
			return obj
		}
		initFilterCopyRef.current = {
			sorty: (() => Object.keys(
				sortyRef.current = {
					'near': '거리순',
					'new': '신규순',
					'order': '누적 주문순',
					'review': '후기 많은순',
				})[0]
			)(),
			minAmount: (() => Object.keys(
				minAmountRef.current = {
					'_all': '전체',
					'_5000': '~5천원',
					'_10000': '~1만원',
					'_15000': '~1만5천원',
					'_20000': '~2만원',
				})[0]
			)(),
			deliveryFee: (() => Object.keys(
				deliveryFeeRef.current = {
					'_all': '전체',
					'_0': '무료',
					'_3000': '~3천원',
					'_5000': '~5천원',
				})[0]
			)(),
			getMethod: (() => objValueToTrue(
				getMethodRef.current = {
					'reservation': '매장식사',
					'takeout': '포장',
					'delivery': '배달'
				})
			)(),
			payMethod: (() => objValueToTrue(
				payMethodRef.current = {
					'kspg': '카드결제',
					'meet': '현장결제',
					'zepg': '강원상품권',
					'knpg_and_hs': '횡성사랑카드',
					'knpg_and_sc': '삼척사랑카드',
					'knpg_and_tb': '태백 탄탄페이',
					'knpg_and_yw': '영월별빛고운카드',
					'knpg_and_dh': '동해페이',
					'chpg_gwwj': '원주사랑카드',
					'chpg_gwhc': '홍천사랑카드',
					'chpg_gwyg': '양구 배꼽페이',
					'chpg_gwjs': '정선 와와페이',
					'knpg_and_ij': '인제채워드림카드',
					'chpg_gwcw': '철원사랑카드',
					'chpg_gwhwacheon': '화천사랑카드',
					'zepg_gwcc': '춘천사랑카드',
					'knpg_and_gs': '고성사랑카드',
					'knpg_gangneung': '강릉페이',
					'chpg_gwsc': '속초사랑카드',
				})
			)(),
			hasCoupon: (() => Object.keys(
				hasCouponRef.current = {
					'all': '전체',
					'y': '있음',
					'n': '없음',
				})[0]
			)(),
		}

		let initFilter = _.cloneDeep(initFilterCopyRef.current)

		/* 정렬순서 분기처리 */
		initFilter.sorty = localStorage.getItem("shopListSorty") || initFilter.sorty

		/* 결제방식 분기처리 */
		const params = new URLSearchParams(location.search)
		let pm = params.get("pm") ? params.get("pm").split("|").map(v => v.toLowerCase()) : []

		//console.log(pm)
		if (pm.length) { /* 쿠폰등 제한적 결제수단표시 필요시 지정한것만 체크되도록 */
			for (const key in initFilter.payMethod) {
				initFilter.payMethod[key] = pm.some(v => key === "meet" ? (v === 'ship' || v === "shop") : v === key)
			}
		}

		/* 상점쿠폰 분기처리 */
		const cp = params.get("cp")
		initFilter.hasCoupon = cp && Object.keys(hasCouponRef.current).includes(cp) ? cp : initFilter.hasCoupon

		return initFilter
	})

	//console.log(filter)
	localStorage.removeItem("shopListSorty") /* shopListSorty는 메인에서 올때임. */

	// 결제방식 클릭 핸들링
	const handleChangeFilter = (e) => {
		const type = e.target.type
		const name = e.target.name
		const value = e.target.value === '' ? null : e.target.value
		const checked = e.target.checked

		if ('radio' === type) {
			setFilter((prevFilter) => ({ ...prevFilter, [name]: value }))
			toggleFilter(name, e)
		}
		if ('checkbox' === type) {
			setFilter((prevFilter) => {
				let newChkBoxValues = { ...prevFilter[name], [value]: checked }

				if ('getMethod' === name) {
					//주문방식: setIsAllOrderType()해줘야함
					setIsAllOrderType(!Object.values(newChkBoxValues).includes(false))
					// 아래는 기존 마지막하나의 체크해지는 안되게 해야하는 방식임
					// if (!Object.values(newChkBoxValues).some(chk => chk === true)) // true가 하나도없으면
					// 	newChkBoxValues = { ...prevFilter[name] } // 기존값그대로준다
				}

				if ('payMethod' === name) {
					//결제방식: setIsAll()해줘야함
					setIsAll(!Object.values(newChkBoxValues).includes(false))
				}
				return { ...prevFilter, [name]: newChkBoxValues }
			})
		}
	}

	// 주문방식 전체선택 기능
	const [isAllOrderType, setIsAllOrderType] = useState(!Object.values(filter.getMethod).includes(false))
	const handleSetAllOrderType = (e) => {
		const checked = e.target.checked
		setFilter((prevFilter) => {
			let newGetMethod = {}
			for (const [key/*, value*/] of Object.entries(prevFilter.getMethod)) {
				newGetMethod[key] = checked
			}
			return { ...prevFilter, getMethod: newGetMethod }
		})
		setIsAllOrderType(checked)
	}
	// 결제방식 전체선택 기능
	const [isAll, setIsAll] = useState(!Object.values(filter.payMethod).includes(false))
	const handleSetAll = (e) => {
		const checked = e.target.checked
		setFilter((prevFilter) => {
			let newPayMethod = {}
			for (const [key/*, value*/] of Object.entries(prevFilter.payMethod)) {
				newPayMethod[key] = checked
			}
			return { ...prevFilter, payMethod: newPayMethod }
		})
		setIsAll(checked)
	}
	// 초기화 
	const handleInitFilter = () => {
		setFilter(_.cloneDeep(initFilterCopyRef.current))
		setIsAll(true)
		setIsAllOrderType(true)
	}

	const selectedTitle = (() => {
		let t = {}
		t.sorty = sortyRef.current[filter.sorty]
		t.minAmount = minAmountRef.current[filter.minAmount]
		t.deliveryFee = deliveryFeeRef.current[filter.deliveryFee]
		t.hasCoupon = hasCouponRef.current[filter.hasCoupon]

		// 주문방식
		const chked_cnt = Object.values(filter.getMethod).filter(v => v === true).length
		if (chked_cnt === Object.values(filter.getMethod).length) {
			t.getMethod = '전체'
		} else {
			const firstChk = Object.keys(getMethodRef.current).find((key) => filter.getMethod[key] === true)

			t.getMethod = firstChk ? getMethodRef.current[firstChk] : '';
			t.getMethod += chked_cnt > 1 ? ` 외 ${chked_cnt - 1}` : '';
			// 아래는 선택한 모든 주문방식을 ,로 join해 표시해주는 것임.
			// t.getMethod = (Object.keys(getMethodRef.current)
			// 	.filter((key) => filter.getMethod[key] === true))
			// 	.map((key) => getMethodRef.current[key])
			// 	.join()
		}

		//결제방식
		const chked_cnt_ = Object.values(filter.payMethod).filter(v => v === true).length
		if (chked_cnt_ === Object.values(filter.payMethod).length) {
			t.payMethod = '전체'
		} else {
			const firstChk_ = Object.keys(payMethodRef.current).find((key) => filter.payMethod[key] === true)

			t.payMethod = firstChk_ ? payMethodRef.current[firstChk_] : '';
			t.payMethod += chked_cnt_ > 1 ? ` 외 ${chked_cnt_ - 1}` : '';
		}

		return t
	})()

	//필터열기
	const [openedFilter, setOpenedFilter] = useState(null)
	const toggleFilter = (type, e) => {
		e.preventDefault()

		if ('hasCoupon' !== type) {
			setOpenedFilter(type === openedFilter ? null : type)
		} else {
			/* 쿠폰은 드롭다운이 없으므로 직접 state갱신하고 끝 */
			setFilter((prevFilter) => ({ ...prevFilter, hasCoupon: prevFilter.hasCoupon === 'all' ? 'y' : 'all' }))
		}
	}

	//필터타이틀클래스명
	const getClassName = (type) => {
		let isOn = false /* on은 드롭다운여부로 핑크바탕적용 */
		let isActive = false /* active는 설정유무로 핑크바탕&화살이미지적용, 단 on이 false일때만이어야 css가 제대로 먹힘 */
		let tpn = 'tp1' /* tp1/2는 쿠폰만 2임 */

		if ('hasCoupon' !== type) {
			isOn = openedFilter === type
			isActive = !_.isEqual(filter[type], initFilterCopyRef.current[type]) && !isOn
		} else {
			isOn = !_.isEqual(filter[type], initFilterCopyRef.current[type])
			tpn = 'tp2'
		}

		return `swiper-slide btnOfilter ${tpn} ${isOn ? 'on' : ''} ${isActive ? 'active' : ''}`
	}

	return props.children(filter,
		<div className="optionFilter">
			<div className="swiper-container2">
				<div className="swiper-wrapper">
					<div className="swiper-slide btnRefesh">
						<p onClick={e => { e.preventDefault(); handleInitFilter() }}>초기화</p>
					</div>
					<div onClick={e => toggleFilter('sorty', e)} className={getClassName('sorty')}>
						<p>정렬 {selectedTitle.sorty}</p>
					</div>
					<div onClick={e => toggleFilter('hasCoupon', e)} className={getClassName('hasCoupon')}>
						<p>쿠폰</p>
					</div>
					<div onClick={e => toggleFilter('minAmount', e)} className={getClassName('minAmount')}>
						<p>최소금액 {selectedTitle.minAmount}</p>
					</div>
					<div onClick={e => toggleFilter('deliveryFee', e)} className={getClassName('deliveryFee')}>
						<p>배달비 {selectedTitle.deliveryFee}</p>
					</div>
					<div onClick={e => toggleFilter('getMethod', e)} className={getClassName('getMethod')}>
						<p>주문방식 {selectedTitle.getMethod}</p>
					</div>
					<div onClick={e => toggleFilter('payMethod', e)} className={getClassName('payMethod')}>
						<p>결제방식 {selectedTitle.payMethod}</p>
					</div>
				</div>
			</div>
			<div className={`optionGp ${openedFilter === 'sorty' ? 'on' : ''}`}>{/*정렬*/}
				<ul>
					{Object.keys(sortyRef.current).map((item, key) => <Radio
						key={`sorty${key}`}
						name="sorty"
						label={sortyRef.current[item]}
						value={item}
						checked={filter.sorty === item}
						onChange={handleChangeFilter}
					/>)}
				</ul>
			</div>
			<div className={`optionGp ${openedFilter === 'minAmount' ? 'on' : ''}`}>{/*최소금액*/}
				<ul>
					{Object.keys(minAmountRef.current).map((item, key) => <Radio
						key={`minAmount${key}`}
						name="minAmount"
						label={minAmountRef.current[item]}
						value={item}
						checked={filter.minAmount === item}
						onChange={handleChangeFilter}
					/>)}
				</ul>
			</div>
			<div className={`optionGp ${openedFilter === 'deliveryFee' ? 'on' : ''}`}>{/*배달비*/}
				<ul>
					{Object.keys(deliveryFeeRef.current).map((item, key) => <Radio
						key={`deliveryFee${key}`}
						name="deliveryFee"
						label={deliveryFeeRef.current[item]}
						value={item}
						checked={filter.deliveryFee === item}
						onChange={handleChangeFilter}
					/>)}
				</ul>
			</div>
			<div className={`optionGp ${openedFilter === 'getMethod' ? 'on' : ''}`}>{/*주문방식*/}
				<ul>
					<CheckBox name="" label="전체" value="" checked={isAllOrderType} onChange={handleSetAllOrderType} />
					{Object.keys(getMethodRef.current).map((item, key) => <CheckBox
						key={`getMethod${key}`}
						name="getMethod"
						label={getMethodRef.current[item]}
						value={item}
						checked={filter.getMethod[item]}
						onChange={handleChangeFilter}
					/>)}
				</ul>
				<a href="#this" onClick={e => { e.preventDefault(); toggleFilter('getMethod', e) }} className="btnApply">적용</a>
			</div>
			<div className={`optionGp ${openedFilter === 'payMethod' ? 'on' : ''}`}>{/*결제방식*/}
				<ul>
					<CheckBox name="" label="전체" value="" checked={isAll} onChange={handleSetAll} />
					{Object.keys(payMethodRef.current).map((item, key) => <CheckBox
						key={`payMethod${key}`}
						name="payMethod"
						label={payMethodRef.current[item]}
						value={item}
						checked={filter.payMethod[item]}
						onChange={handleChangeFilter}
					/>)}
				</ul>
				<a href="#this" onClick={e => { e.preventDefault(); toggleFilter('payMethod', e) }} className="btnApply">적용</a>
			</div>
		</div>,
		<div className="bgModal60" style={{ display: _.isNull(openedFilter) ? 'none' : 'block' }}></div >
	)
}
function CheckBox(props) {
	return <li>
		<label className="chkBox1">
			<input type="checkbox" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
			<span className="icon"></span>
			<span className="text">{props.label}</span>
		</label>
	</li>
}
function Radio(props) {
	return <li>
		<label className="rdoBox1">
			<input type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
			<span className="icon"></span>
			<span className="text">{props.label}</span>
		</label>
	</li>
}