import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Popup from './Popup';

class TermsList extends React.Component {
	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>제공 서비스별 약관 </h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="mpListMenu">
							<ul>
								<li><Popup href="https://smallbiz.notion.site/_-9ce2143a0eb7495185c6bd8e04ad8ef8">통합회원 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-64f3e6d192d74790bbe6ecd7b84704c3">서비스 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-97096fa5dcea40c7b19920f165ed39ae">개인정보처리방침</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-2ba9f3be40074808bdfb33c6b2991f75">위치기반 서비스 이용약관</Popup></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(TermsList)