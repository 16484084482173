import React, { useRef, useState, useEffect } from "react";
import $ from 'jquery'
import QueryString from 'query-string'
import Footer from './Footer'
import { withRouter, Link, useLocation, Route, useHistory, useRouteMatch } from 'react-router-dom'
import BottomScrollListener from 'react-bottom-scroll-listener'
import { Spin } from 'antd'
import Modal from './CustomModal'
import { LoadingOutlined } from '@ant-design/icons'
import { loader } from 'graphql.macro'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { gqlErrorHandler, chkImageUrl, handleToOrder } from './Library'
import OrderDetail from "./OrderDetail";
import { Transition } from "react-transition-group";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const orderListGql = loader('../gql/order_list.gql')
const orderCancelGql = loader('../gql/order_cancel.gql')
const orderFinishGql = loader('../gql/order_finish.gql')
const orderDisplayGql = loader('../gql/order_display.gql')

class Order extends React.Component {
	componentDidMount() {
		// 라우트주소 변경 감지 코드, 나중에 필요시 참고하도록.
		this.unlisten = this.props.history.listen((location, action) => {
			// console.log(location) 
		})
	}
	componentWillUnmount() {
		this.unlisten()
	}
	getLink = (goto) => {
		let search = QueryString.parse(this.props.location.search);
		search = { orderType: '', ...search, ...goto }
		return {
			pathname: '/order',
			search: QueryString.stringify(search)
		}
	}
	isActive = (obj) => {
		let search = QueryString.parse(this.props.location.search);
		search = { orderType: '', ...search }

		let active = true;
		for (const qn in obj) {
			if (obj[qn] !== search[qn]) {
				active = false;
				break;
			}
		}
		return active;
	}

	render() {
		return <div id="reWrap">
			<Route path={`${this.props.match.path}`}>
				<div id="header" className="subHeader5">
					<div className="header">
						<h1 className="txtH1L">주문내역</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts7">
						<div className="tabSt1">
							<ul>
								<li>
									<button className={this.isActive({ orderType: '' }) ? 'on' : ''}>
										<Link to={this.getLink({ orderType: '' })}>전체</Link>
									</button>
								</li>
								<li>
									<button className={this.isActive({ orderType: 'DELIVERY' }) ? 'on' : ''}>
										<Link to={this.getLink({ orderType: 'DELIVERY' })}>배달</Link>
									</button>
								</li>
								<li>
									<button className={this.isActive({ orderType: 'TAKEOUT' }) ? 'on' : ''}>
										<Link to={this.getLink({ orderType: 'TAKEOUT' })}>픽업</Link>
									</button>
								</li>
								<li>
									<button className={this.isActive({ orderType: 'SHOP' }) ? 'on' : ''}>
										<Link to={this.getLink({ orderType: 'SHOP' })}>매장식사</Link>
									</button>
								</li>
							</ul>
						</div>
						<div className="orderSection">
							<OrderList />
						</div>
					</div>
				</div>
				<Footer />
			</Route>
			<Route path={`${this.props.match.path}/:id`}>
				<OrderDetail />
			</Route>
		</div>
	}
}
const OrderList = (props) => {
	const doFetchMore = useRef(true)
	useEffect(() => {
		//기타버튼 레이어 show,hide
		$(document).on('click', function (e) {
			e.preventDefault();
			if (!$(e.target).hasClass('btnEtc')) {
				//외부클릭시모두닫기
				$('.btnEtc').removeClass('on').next().slideUp('fast');
			} else {
				//선택레이어토클
				$(e.target).toggleClass('on').next().slideToggle('fast');
				//다른레이어닫기
				$('.btnEtc').not($(e.target)).removeClass('on').next().slideUp('fast');
			}
		})
		return () => $(document).off('click')
	})
	const history = useHistory()
	let match = useRouteMatch()
	const location = useLocation()

	let search = QueryString.parse(location.search);
	search = { orderType: '', ...search }
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		status: "PAY,WORKING,FINISH,CANCEL",
		day: 180,
		...search,
		first: 15,
	}

	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
	const [cancelIng, setCancelIng] = useState(false);
	const { error, data, fetchMore, refetch, networkStatus } = useQuery(orderListGql, { variables: values, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
	const [orderCancel] = useMutation(orderCancelGql, {
		onCompleted() { refetch(); setCancelIng(false) },
		onError(err) { gqlErrorHandler(err) }
	})
	const [orderFinish] = useMutation(orderFinishGql, {
		onCompleted() { refetch(); setCancelIng(false) },
		onError(err) { gqlErrorHandler(err) }
	})
	const [orderHidden] = useMutation(orderDisplayGql, {
		onCompleted() { refetch(); setCancelIng(false) },
		onError(err) { gqlErrorHandler(err) }
	})
	if (error) { gqlErrorHandler(error); return null; }
	if (!data)
		return (
			<div style={{ paddingLeft: '48%', paddingTop: '50%', marginTop: 5 }}>
				<Spin tip="" indicator={antIcon} />
			</div>
		)
	// console.log(data)

	const handleOrderCancel = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>주문을 취소하시겠습니까?<br />취소 이후엔 수정이 불가능합니다.</>,
			okText: "확인",
			cancelText: "취소",
			onOk: () => {
				setCancelIng(true)
				const variables = {
					shopId: order.shop.pk,
					token: order.token,
				}
				orderCancel({ variables })
			}
		})
	}
	const handleOrderFinish = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>주문하신 상품(음식)을 정말<br /> 받으셨습니까?<br /><span className="txt-red txt-c">* 수취 확인 이후 취소는 불가능합니다.</span></>,
			okText: "확인",
			cancelText: "취소",
			onOk: () => {
				setCancelIng(true)
				const variables = {
					shopId: order.shop.pk,
					token: order.token,
				}
				orderFinish({ variables })
			}
		})
	}
	const handleOrderHidden = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>선택한 주문내역을 삭제하시겠습니까?<br />삭제 후에는 복구할 수 없습니다.</>,
			okText: "확인",
			cancelText: "취소",
			onOk: () => {
				setCancelIng(true)
				const variables = {
					orderId: order.pk,
				}
				orderHidden({ variables })
			}
		})
	}
	const handleFetchMore = async (fm, data) => {
		if (data.gongOrders.pageInfo.hasNextPage === false) return
		if (doFetchMore.current === false) return

		doFetchMore.current = false

		await fm({
			variables: {
				cursor: data.gongOrders.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongOrders.edges;
				const pageInfo = fetchMoreResult.gongOrders.pageInfo;

				return newEdges.length
					? {
						gongOrders: {
							__typename: previousResult.gongOrders.__typename,
							edges: [...previousResult.gongOrders.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})

		doFetchMore.current = true
	}
	let rwin
	let timer
	const isTimeOver = (t) => {
		let d = new Date()
		d.setDate(d.getDate() - 3)
		const sd = d.getFullYear() + '.' + ('0' + (d.getMonth() + 1)).substr(-2) + '.' + ('0' + d.getDate()).substr(-2)
		// console.log(t, sd, t < sd)
		return t < sd
	}
	const handleWriteReview = (e, node) => {
		e.preventDefault();
		if (isTimeOver(node.orderAt)) { // finishAt는 현재 값이 없어서 orderAt로 했음.
			Modal.warning({ content: '리뷰 작성 기간이 지났습니다.' });
		} else {
			rwin = window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/order/review/${node.token}/?token=${localStorage.getItem('access_token')}`);
			timer = window.setInterval(ifWindowClosed, 500)
		}
	}
	const ifWindowClosed = () => {
		if (rwin.closed === true) {
			refetch() // 수정된 내역 반영하기
			window.clearInterval(timer);
		}
	}
	/**
	 * 6시간 전후의 주문 css가 다름, ing ed로 나워서 처리.
	 * margin-left값 변경 시 base.css에서의 설정과 함께 고려하도록.
	 */
	const duration = 500; // 트랜지션 지속시간
	const defaultStyleCommon = { transition: `opacity ${duration}ms ease, margin-left ${duration}ms ease`, opacity: 0 } // 트랜지션 전 공통
	const defaultStyle = { // 트랜지션 전 나눔
		ing: { ...defaultStyleCommon, marginLeft: 46 },
		ed: { ...defaultStyleCommon, marginLeft: 30 }
	}

	const transitionStylesCommon = { opacity: 1 } // 트랜지션 후 공통
	const transitionStyles = { // 트랜지현 후 나눔
		ing: { entered: { ...transitionStylesCommon, marginLeft: 16 } },
		ed: { entered: { ...transitionStylesCommon, marginLeft: 0 } }
	};
	return <div className="tabSectoin" style={{ display: 'block' }}>
		{data.gongOrders.edges.length > 0
			? <BottomScrollListener onBottom={() => handleFetchMore(fetchMore, data)}>
				{(cancelIng === true || networkStatus === NetworkStatus.refetch) && <Loading /> /* 주문취소처리 중일 때 덮는 레이어 */}
				<div className="myOrderState">
					<div className="myOrderList">
						<ul>
							{data.gongOrders.edges.map((item) => {
								let step = 0
								if (item.node.orderStatus === 'PAY') step = 1
								if (item.node.orderStatus === 'WORKING') step = 2
								if (item.node.orderStatus === 'SHIPPING') step = 3
								if (item.node.orderStatus === 'FINISH') step = 4

								return <Transition key={item.node.pk} in appear timeout={duration}>
									{state => {
										const orderState = dayjs().isAfter(dayjs(item.node.orderAt, 'YYYY.MM.DD HH:mm').add(6, 'hour')) ? 'ed' : 'ing' // 6시간 이전만 카드뷰
										const style = { ...defaultStyle[orderState], ...transitionStyles[orderState][state] }

										return <li className={orderState === 'ing' ? 'orderState' : ''} style={style}>
											<div className="box">
												<div className="img" onClick={(e) => handleToOrder(item.node.shop.pk, e, history)}><img src={chkImageUrl(item.node.shop.img)} onError={e => e.target.style.display = 'none'} alt="" /></div>
												<div className="cnt">
													<div className="state">
														<span>{dayjs(item.node.orderAt, 'YYYY.MM.DD HH:mm').format('YY-MM-DD')}</span>
														{item.node.orderStatus === 'CANCEL' && <span className="now cancle">취소완료</span>}
														{item.node.orderType === 'SHOP' && item.node.orderStatus !== 'CANCEL' && (item.node.orderStatus === 'FINISH' ? <span className="now">매장완료</span> : <span className="icoState3">매장식사</span>)}
														{item.node.orderType === 'TAKEOUT' && item.node.orderStatus !== 'CANCEL' && (item.node.orderStatus === 'FINISH' ? <span className="now">픽업완료</span> : <span className="icoState2">픽업</span>)}
														{item.node.orderType === 'DELIVERY' && item.node.orderStatus !== 'CANCEL' && (item.node.orderStatus === 'FINISH' ? <span className="now">배달완료</span> : <span className="icoState1">배달</span>)}
													</div>
													<p>{item.node.shop.name}</p>
													<span className="menu">{item.node.goodsName}</span>
													<a href="#this" className="txtHide btnEtc">기타</a>
													<div className="layerEtc">
														<a href="#this" onClick={(e) => handleToOrder(item.node.shop.pk, e, history)}>상점보기</a>
														{orderState !== 'ing' && <a href="#this" onClick={(e) => handleOrderHidden(e, item.node)}>주문내역 삭제</a>}
													</div>
												</div>
											</div>
											<div className="btnsTwice">
												<span><a href="#this" onClick={(e) => { e.preventDefault(); history.push(`${match.url}/${item.node.pk}${location.search}`) }} className="btnTy5">주문 상세</a></span>
												{item.node.orderStatus === 'PAY' &&
													<span><a href="#this" onClick={(e) => handleOrderCancel(e, item.node)} className="btnTy5">주문 취소</a></span>
												}
												{(item.node.orderStatus === 'WORKING' || item.node.orderStatus === 'SHIPPING') && item.node.orderType === 'DELIVERY' &&
													<span><a href="#this" onClick={(e) => handleOrderFinish(e, item.node)} className="btnTy5">수취 확인</a></span>
												}
												{item.node.orderStatus === 'FINISH' &&
													<span>
														<a href="#this" onClick={(e) => handleWriteReview(e, item.node)} className={`btnTy6 ${isTimeOver(item.node.orderAt) ? 'confirm' : ''}`} style={isTimeOver(item.node.orderAt) ? {} : { color: '#7675ed' }}>
															{item.node.hasReview ? '리뷰 수정' : '리뷰 쓰기'}
														</a>
													</span>
												}
											</div>
											{orderState === 'ing' && item.node.orderStatus !== 'CANCEL' &&
												<div className={`deliveryState ${item.node.orderType === 'SHOP' ? 'state3' : (item.node.orderType === 'TAKEOUT' ? 'state2' : 'state1')}`} >
													<p>
														{item.node.visitAt && (item.node.orderStatus === 'WORKING' || item.node.orderStatus === 'SHIPPING' || item.node.orderStatus === 'FINISH') &&
															<>
																{dayjs(item.node.visitAt, 'YYYY.MM.DD HH:mm').format('A') === 'AM' ? '오전' : '오후'} {dayjs(item.node.visitAt, 'YYYY.MM.DD HH:mm').format('hh:mm ')}
																<strong>
																	{item.node.orderType === 'SHOP' && '매장준비'}
																	{item.node.orderType === 'TAKEOUT' && '픽업준비'}
																	{item.node.orderType === 'DELIVERY' && '배달완료'}
																</strong> 예정
															</>
														}
													</p>
													{item.node.orderType === 'DELIVERY'
														? <ul>
															<li className={step === 1 ? 'ing' : (step > 1 ? 'past' : '')}><span>주문확인</span></li>
															<li className={step === 2 ? 'ing' : (step > 2 ? 'past' : '')}><span>준비중</span></li>
															<li className={step === 3 ? 'ing' : (step > 3 ? 'past' : '')}><span>배달중</span></li>
															<li className={step === 4 ? 'ing' : (step > 4 ? 'past' : '')}><span>배달완료</span></li>
														</ul>
														: <ul>
															<li className={step === 1 ? 'ing' : (step > 1 ? 'past' : '')}><span>주문확인</span></li>
															<li className={step === 2 ? 'ing' : (step > 2 ? 'past' : '')}><span>준비중</span></li>
															<li className={step === 4 ? 'ing' : (step > 4 ? 'past' : '')}><span>준비완료</span></li>
														</ul>
													}
												</div>
											}
										</li>
									}}
								</Transition>
							})}
						</ul>
					</div>
				</div>
				{data.gongOrders.pageInfo.hasNextPage === true &&
					<div style={{ display: 'grid', placeItems: 'center' }}>
						<Spin tip="" indicator={antIcon} />
					</div>
				}
			</BottomScrollListener>
			: <div className="myOrderList">
				<ul>
					<li className="noData">
						<p>주문내역이 없습니다.</p>
					</li>
				</ul>
			</div>
		}
	</div>
}
class Loading extends React.Component {// 주문취소 시 로딩 용
	constructor(props) {
		super(props);
		this.antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />
	}
	componentDidMount() {
		document.querySelector('body').setAttribute('style', "overflow: hidden")
	}
	componentWillUnmount() {
		document.querySelector('body').setAttribute('style', "")
	}
	render() {
		return (
			<>
				<div id="layer" className="layer-wrap" style={{ display: 'block' }}>
					<Spin tip="" indicator={this.antIcon} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 100 }} />
				</div>
				<div id="overlay" style={{ display: 'block' }}></div>
			</>
		)
	}
}

export default withRouter(Order)