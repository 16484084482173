import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import Footer from './Footer'
import { Link, useLocation, useHistory, withRouter, Route } from 'react-router-dom'
import { useHeaderAddr } from '../hook/useAddrInfo'
import { createKakaoMap, gqlErrorHandler, chkImageUrl, handleToOrder } from './Library'
import CategoryMenu from './CategoryMenu'
import Filter from './Filter'
import ShopList from './ShopList'
import useShopListParam from "../hook/useShopListParam"
import usePayTypeName from "../hook/usePayTypeName"
import { loader } from 'graphql.macro'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import withLocAddressMain from "./withLocAddressMain"
//import SwipeableRoutes from "react-swipeable-routes";
import SwipeRouter from './SwipeRouter';

const shopListGql = loader('../gql/shop_list.gql')
const formatter = new Intl.NumberFormat()

function Shop() {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const fromUrlmode = params.get('mode'); // /shop/12?mode=map 로 들어올 때 UI유지되도록

	const [mode, setMode] = useState(() => fromUrlmode || 'list')
	const handleToggleMode = () => {
		setMode(mode === 'list' ? 'map' : 'list')
	}
	return (
		<div id="reWrap">
			<Filter>
				{(filter, filterUI, filterBG) => {
					return mode === 'list'
						? <ListUI {...{ filter, filterUI, filterBG, handleToggleMode }} />
						: <MapUI  {...{ filter, filterUI, filterBG, handleToggleMode }} />
				}}
			</Filter>
		</div>
	)
}

function ListUI(props) {
	const [activeCategoryIndex, setActiveCategoryIndex] = useState();
	const addressTxt = useHeaderAddr()
	const propsFilter = props.filter;
	const categoary = [
		{ cateCode: 36, name: '카페/디저트' },
		{ cateCode: 37, name: '한식' },
		{ cateCode: 38, name: '분식' },
		{ cateCode: 39, name: '일식/회' },
		{ cateCode: 40, name: '양식/돈까스' },
		{ cateCode: 41, name: '중식' },
		{ cateCode: 42, name: '치킨/피자' },
		{ cateCode: 43, name: '족발/보쌈' },
		{ cateCode: 44, name: '야식' },
		{ cateCode: 45, name: '찜/탕' },
		{ cateCode: 46, name: '도시락' },
		{ cateCode: 47, name: '패스트푸드' },
		{ cateCode: 48, name: '청과/정육' },
		// { cateCode: 'oneteam', name: '봉의산원팀'},
	]
	const onSwitchCategory = (index, type) => {
		if (type === 'end') {
			setActiveCategoryIndex(index);
		}
	}
	return (
		<>
			<div id="header" className="subHeader2">
				<div className="header">
					<div className="adr"><Link to='/mypage/set-address'>{addressTxt}</Link></div>
					<div className="btnHr">
						<a href="#this" className="btnMap" onClick={e => { e.preventDefault(); props.handleToggleMode() }}>지도</a>
					</div>
				</div>
				<div className="hdMenu">
					<CategoryMenu sUrl='/shop' activeIndex={activeCategoryIndex} doScrollAnimation />
				</div>
				{props.filterUI}
			</div>
			{props.filterBG}
			<div id="container">
				<SwipeRouter replace id="contents" className={"subCnts2"} enableMouseEvents onSwitching={onSwitchCategory} >
					<Route path="/shop">
						<ShopList filter={propsFilter} match={{ path: "/shop" }} />
					</Route>
					{categoary.map((item, k) => {
						return (
							<Route key={k} path={"/shop/" + item.cateCode}>
								<ShopList filter={propsFilter} match={{ path: "/shop/" + item.cateCode }} />
							</Route>
						)
					})}
				</SwipeRouter>
			</div>
			<Footer />
		</>
	)
}
const MapUI = withLocAddressMain(withRouter(class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mapAdded: false,
			reCoords: null,
		}
		this.geo = {
			coords: `${this.props.locAddressMain.lng},${this.props.locAddressMain.lat}`,
			lat: this.props.locAddressMain.lat,
			lng: this.props.locAddressMain.lng,
		}
		this.currentOverlay = null
		this.currentPks = []
		this.markerList = new Map()/* pk => marker */
		this.overlayList = new Map()/* pk => overlay */
		this.handleClickList = new Map()/* pk => handleClick */
	}

	componentDidMount() {
		createKakaoMap({ // 카카오맵 생성
			id: 'kakao-map',
			lat: this.geo.lat,
			lng: this.geo.lng,
			level: 4
		}, (map) => {
			this.map = map
			this.markerImage = new window.kakao.maps.MarkerImage('/img/reImg/common/ico_map_pin.png', new window.kakao.maps.Size(23, 28))
			this.setState({ mapAdded: true })
		})
	}
	componentWillUnmount() {
		document.head.removeChild(document.getElementById('kakao-map-script'))
	}
	makeMarker = (list) => {
		this.currentPks = [];
		for (const item of list) this.currentPks = [...this.currentPks, item.node.shop.pk]

		/* 기존 마커/오버레이어/리스너들 중 필요없는것들 삭제 */
		for (const [pk, marker] of this.markerList) {
			if (this.currentPks.indexOf(pk) === -1) {
				window.kakao.maps.event.removeListener(marker, 'click', this.handleClickList.get(pk))
				marker.setMap(null)
				this.overlayList.get(pk).setMap(null)

				this.markerList.delete(pk)
				this.overlayList.delete(pk)
				this.handleClickList.delete(pk)
			}
		}

		/* 
		* 마커/오버레이어/리스너 생성.
		* 기존에 있으면 재활용, 없으면 생성. 
		* 마커의 깜빡임 최소화하려고.
		*/
		for (const key in list) {
			const item = list[key];

			if (!this.markerList.has(item.node.shop.pk)) { // 있으면 다시만들필요없음 재활용, 없으면 marker,overlay,handleClick 생성.
				const coords = new window.kakao.maps.LatLng(item.node.shop.lat, item.node.shop.lng)
				const marker = new window.kakao.maps.Marker({
					map: this.map,
					position: coords,
					image: this.markerImage,
					clickable: true,
				})
				this.markerList.set(item.node.shop.pk, marker)

				const content = `<div class="map-bubble-title">${item.node.shop.name}</div>`
				const customOverlay = new window.kakao.maps.CustomOverlay({
					position: coords,
					content,
					xAnchor: 0.5,
					yAnchor: 2.2,

				})
				this.overlayList.set(item.node.shop.pk, customOverlay)

				const handleClick = () => {
					toClickedStore(this.currentPks.indexOf(item.node.shop.pk))/* key는 변하므로 직접 쓰면 안됨. */
					this.currentOverlay && this.currentOverlay.setMap(null) /* 기존 상점명레이어 지도에서 제거 */
					this.currentOverlay = customOverlay
					this.currentOverlay.setMap(this.map) /* 클릭한 마커에 상점명 붙이기 */
				}
				this.handleClickList.set(item.node.shop.pk, handleClick)
				window.kakao.maps.event.addListener(marker, 'click', handleClick)
			}
			if (key === '0') {
				this.currentOverlay && this.currentOverlay.setMap(null) /* 기존 상점명레이어 지도에서 제거 */
				this.currentOverlay = this.overlayList.get(item.node.shop.pk)
				this.currentOverlay.setMap(this.map) /* 처음 마커에 상점명 붙이기 */
				this.map.panTo(new window.kakao.maps.LatLng(item.node.shop.lat, item.node.shop.lng)) /* 보기좋게 맵중앙으로 */
			}
		}
	}
	handleReturn = (e) => { // 돌아오기
		e.preventDefault()
		this.map.panTo(new window.kakao.maps.LatLng(this.geo.lat, this.geo.lng))
	}
	handleResearch = (e) => {
		e.preventDefault()
		const coords = this.map.getCenter()
		this.setState({
			reCoords: `${coords.getLng()},${coords.getLat()}`,
		})
	}
	render() {
		return (
			<>
				<div id="header" className="subHeader2">
					<div className="header">
						<div className="btnList" onClick={this.props.handleToggleMode}><a href="#this" className="txtHide" onClick={e => e.preventDefault()}>뒤로가기</a></div>
						<h1>매장보기</h1>
					</div>
					<div className="hdSoloMenu">
						<CategoryMenu sUrl='/shop' />
					</div>
					{this.props.filterUI}
				</div>
				{this.props.filterBG}
				<div id="container">
					<div id="contents" className="subCnts3">
						<div className="mapArea">
							<div id='kakao-map' style={{ width: '100%', height: '100%' }}></div>
						</div>
						{this.state.mapAdded && <AroundShop filter={this.props.filter} reCoords={this.state.reCoords} makeMarker={this.makeMarker} handleResearch={this.handleResearch} />}
					</div>
				</div>
			</>
		)
	}
}))
function AroundShop({ filter, reCoords, makeMarker, handleResearch }) {
	const values = useShopListParam({ filter, reCoords })

	const { loading, error, data } = useQuery(shopListGql, { variables: values })
	// console.log(loading, error, data)
	if (loading) return null
	if (error) { gqlErrorHandler(error); return null }

	makeMarker(data.gongShops.edges)

	return (
		<div className="shopDetailBox" >
			<div className="btnLocalSch">
				<a href="#this" onClick={handleResearch}>이 주변 검색</a>
			</div>
			<div className="shopLocalList">
				<div className="box swiper-container">
					{data.gongShops.edges.length > 0
						? <ShopLine edges={data.gongShops.edges} />
						: <ul>
							<li className="noData">
								<div className="box">이 주변에는<br />등록된 상점이 없습니다.</div>
							</li>
						</ul>
					}
				</div>
			</div>
		</div>
	)
}

let shop_line_swiper = {}
const toClickedStore = (key) => shop_line_swiper.slideTo(key, 1000)

function ShopLine(props) { /* useEffect가 있으므로 AroundShop와 합치기보다 따로 빼는게 좋다. */
	//지도 위 상점 목록
	useEffect(() => {
		shop_line_swiper = new Swiper('.shopLocalList .swiper-container', {
			slidesPerView: 'auto',
			spaceBetween: 10,
			centeredSlides: true,
		});
		return () => shop_line_swiper = {}
	})
	return (
		<ul className="swiper-wrapper">
			{props.edges.map((item) => (
				<ShopUnit {...item.node} key={item.node.shop.pk} />
			))}
		</ul>
	)
}
function ShopUnit(props) {
	const history = useHistory()

	const payTypeName = usePayTypeName(props.shop.payType)

	return (
		<li className="swiper-slide">
			<a href="#this" onClick={(e) => handleToOrder(props.shop.pk, e, history)}>
				<div className="img"><img src={chkImageUrl(props.shop.img)} alt="이미지" onError={e => e.target.style.display = 'none'} /></div>
				<div className="cnt">
					<h3>{props.shop.name}</h3>
					<div className="divState">
						{props.shop.useReservation && <span className="icoSt">매장</span>}
						{props.shop.useTakeout && <span className="icoTo">포장</span>}
						{props.shop.useDelivery && <span className="icoDe">배달</span>}
						{props.shop.hasCoupon && <span className="icoCo">쿠폰</span>}
					</div>
					<p>
						<span className="distance">
							{props.m < 1000
								? props.m + 'm'
								: formatter.format((props.m / 1000).toFixed(1)) + 'km'
							}
						</span>
						<span>최소주문금액 {formatter.format(props.shop.deliveryMinPrice)}원</span>
						{props.shop.useDelivery && <span className="fee">배달팁 {formatter.format(props.shop.shipMinPrice)} {(props.shop.shipMaxPrice > props.shop.shipMinPrice) && ` ~ ${formatter.format(props.shop.shipMaxPrice)}`}원</span>}
					</p>
					<p className="payHow">
						{payTypeName.join('·')}
					</p>
				</div>
			</a>
		</li>
	)
}
export default Shop