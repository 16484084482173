export default function (shopPaytype) {
	let payType = []
	for (const key in shopPaytype) {
		let item = shopPaytype[key]
		if (item === 'SHOP' || item === 'SHIP') item = 'SHOP_SHIP' /* 매장에서 결제, 만나서 결제 는 모두 현장으로 표시 */
		if (!payType.includes(item)) payType = [...payType, item]
	}
	/* 카드결제->현장결제->강원상품권->코나아이화폐 순으로 */
	if (payType.includes('ZEPG')) payType = ['ZEPG', ...payType]
	if (payType.includes('SHOP_SHIP')) payType = ['SHOP_SHIP', ...payType]
	if (payType.includes('KSPG')) payType = ['KSPG', ...payType]

	payType = [...new Set(payType)] /* unique처리 */

	let payTypeName = []
	payType.forEach((item) => {
		let name = false
		!name && (name = item === 'KSPG' && '카드')
		!name && (name = item === 'SHOP_SHIP' && '현장')
		!name && (name = item === 'ZEPG' && '강원상품권')
		!name && (name = item === 'KNPG_AND_HS' && '횡성사랑카드')
		!name && (name = item === 'KNPG_AND_SC' && '삼척사랑카드')
		!name && (name = item === 'KNPG_AND_TB' && '태백 탄탄페이')
		!name && (name = item === 'KNPG_AND_YW' && '영월별빛고운')
		!name && (name = item === 'KNPG_AND_DH' && '동해페이')
		!name && (name = item === 'KNPG_GANGNEUNG' && '강릉페이')
		!name && (name = item === 'CHPG_GWWJ' && '원주사랑카드')
		!name && (name = item === 'CHPG_GWHC' && '홍천사랑카드')
		!name && (name = item === 'CHPG_GWYG' && '양구 배꼽페이')
		!name && (name = item === 'CHPG_GWJS' && '정선 와와페이')
		!name && (name = item === 'KNPG_AND_IJ' && '인제채워드림카드')
		!name && (name = item === 'CHPG_GWCW' && '철원사랑카드')
		!name && (name = item === 'CHPG_GWHWACHEON' && '화천사랑카드')
		!name && (name = item === 'ZEPG_GWCC' && '춘천사랑카드')
		!name && (name = item === 'KNPG_AND_GS' && '고성사랑카드')
		!name && (name = item === 'CHPG_GWSC' && '속초사랑카드')
		name && (payTypeName = [...payTypeName, name])
	})
	return payTypeName
}