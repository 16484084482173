import React from "react";
import InputDelButton from './InputDelButton';
import Timer from './Timer';
import { withRouter } from "react-router-dom";
import axios from "axios";
import Modal from './CustomModal';

const style_popup_wrapper = { opacity: 1, visibility: 'visible', position: 'fixed', overflow: 'auto', zIndex: 101, transition: 'all 0.3s ease 0s', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center', pointerEvents: 'none' };
const style_popup_content = { top: '50vh', transform: 'translateY(-50%)', opacity: 1, visibility: 'visible', pointerEvents: 'auto', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle' };
const style_popup_background = { opacity: 0.6, visibility: 'visible', backgroundColor: 'black', position: 'fixed', inset: 0, zIndex: 100, transition: 'all 0.3s ease 0s' };

class FindEmail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: { mobile: '', confirmNo: '' },
			inputFocus: '',
			msgSendDate: null,
			msgTimeout: false,
			confirmed: false,
			findEmail: ''
		};

		this.handleInput = this.handleInput.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleTimeout = this.handleTimeout.bind(this);
	}

	handleTimeout() {
		this.setState({ msgTimeout: true }, () => console.log('msg timeout!', this.state));
		return false;
	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);
		this.setState({ values: values });
	}

	handleSend(e) {
		e.preventDefault();
		let values = this.state.values;
		if (!values.mobile) return;
		axios.get(`${process.env.REACT_APP_SERVER_REST}/member/find_email?phone=${values.mobile}`)
			.then(res => {
				if (res.data.result) {
					Modal.success({ content: '인증번호를 전송했습니다.' });
					// 인증번호가 전송되었을 경우 전송된 휴대폰번호를 저장
					values.sendedMobile = values.mobile;
					this.setState({ values: values, msgSendDate: new Date().getTime(), msgTimeout: false });
				} else {
					Modal.warning({ content: res.data.msg });
				}
			})
			.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
	}

	handleSubmit(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.sendedMobile && values.confirmNo) {
			axios
				.get(`${process.env.REACT_APP_SERVER_REST}/member/find_email_ok?phone=${values.sendedMobile}&auth=${values.confirmNo}`)
				.then(res => {
					// 인증성공
					if (res.data.result) {
						if (res.data.email) {
							this.setState({ confirmed: true, findEmail: res.data.email })
						} else {
							Modal.warning({
								content: '가입된 휴대폰 번호가 아닙니다.'
							});
						}
						// 인증실패
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
		}
	}

	render() {
		const onClassSubmit = (this.state.values.sendedMobile && this.state.values.confirmNo && this.state.msgTimeout === false) ? 'on' : '';
		const onClassSendmsg = this.state.values.mobile ? 'on' : '';
		const { confirmed, findEmail } = this.state;

		if (confirmed === true && findEmail !== '') {
			return (
				<>
					<div className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
						<div id="layerPwFind" className="layerAlertCom popup_content" style={style_popup_content}>
							<div className="layerBox">
								<div className="layerHd">
									<h3>아이디 찾기</h3>
								</div>
								<div className="layerCnt">
									<div className="layerFindConfirm">
										<p className="idP" style={{paddingTop: 0}}>
											<em>
												고객님의 아이디는<br />
												<strong>{findEmail}</strong> 입니다.
											</em>
										</p>
										<span>아이디 확인 후 로그인해 주세요.</span>
									</div>
								</div>
								<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnLayerClose1 layerPwFind_close">닫기</a>
							</div>
						</div>
					</div>
					<div className="popup_background" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} style={style_popup_background}></div>
				</>
			)
		} else {
			return (
				<>
					<div id="layerIdFind_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
						<div id="layerIdFind" className="popup_content" style={style_popup_content}>
							<div className="layerBox">
								<div className="layerHd">
									<h3>아이디 찾기</h3>
								</div>
								<div className="layerCnt">
									<div className="layerCom">
										<h4>휴대폰 번호</h4>
										<div className="box telLi">
											<div className="inputSt1">
												<InputDelButton type="tel" name="mobile" className="clearInput" placeholder="휴대폰 번호 입력" onInput={this.handleInput} />
												<div className="inputFocus"></div>
											</div>
											<a href="#this" onClick={this.handleSend} className={`btnTy4  ${onClassSendmsg}`}>{!this.state.values.sendedMobile ? "발송" : "재발송"}</a>
										</div>
										<h4>인증번호</h4>
										<div className="box numLi">
											<div className="inputSt1">
												<InputDelButton type="tel" name="confirmNo" maxLength={4} className="clearInput" placeholder="4자리" onInput={this.handleInput} />
												<div className="inputFocus"></div>
												{this.state.values.sendedMobile && <Timer onTimeout={this.handleTimeout} startTime={this.state.msgSendDate} />}
											</div>
										</div>
										<div className="btnCenter">
											{this.state.msgTimeout === false ?
												<a href="#this" className={`btnTy2  ${onClassSubmit}`} onClick={this.handleSubmit} >확인</a>
												:
												<a href="#this" className={`btnTy2`} onClick={(e) => e.preventDefault()}>확인</a>
											}
										</div>
									</div>
								</div>
								<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnLayerClose1 layerIdFind_close">닫기</a>
							</div>
						</div>
					</div>
					<div id="layerIdFind_background" className="popup_background" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} style={style_popup_background}></div>
				</>
			)
		}
	}
}


export default withRouter(FindEmail)