import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Modal from './CustomModal';
import InputDelButton from "./InputDelButton";

const style_popup_wrapper = { opacity: 1, visibility: 'visible', position: 'fixed', overflow: 'auto', zIndex: 101, transition: 'all 0.3s ease 0s', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center', pointerEvents: 'none' };
const style_popup_content = { top: '50vh', transform: 'translateY(-50%)', opacity: 1, visibility: 'visible', pointerEvents: 'auto', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle' };
const style_popup_background = { opacity: 0.6, visibility: 'visible', backgroundColor: 'black', position: 'fixed', inset: 0, zIndex: 100, transition: 'all 0.3s ease 0s' };

class FindPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = { values: { email: '' }, confirmed: false, };
		this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);
		this.setState({ values: values });
	}

	handleSubmit(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.email) {
			axios
				.get(`${process.env.REACT_APP_SERVER_REST}/member/find_pw?email=${values.email}`)
				.then(res => {
					// 인증성공
					if (res.data.result) {
						this.setState({ confirmed: true });
					// 인증실패
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '오류가 발생하였습니다.' }) });
		}
	}

	render() {
		const onClassSend = this.state.values.email ? 'on' : '';
		const { confirmed, values } = this.state;

		if (confirmed === true && values.email !== '') {
			return (
				<>
					<div id="layerPwFind_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
						<div id="layerPwFind" style={style_popup_content}>
							<div className="layerBox">
								<div className="layerHd">
									<h3>비밀번호 찾기</h3>
								</div>
								<div className="layerCnt">
									<div className="layerFindConfirm">
										<p className="pwP">
											<em>
												<strong>{values.email}</strong>으로<br />
												비밀번호 재설정 메일이 발송되었어요.
											</em>
										</p>
										<span>5분 후에도 메일이 오지 않는다면<br />스팸함을 확인해 주세요.</span>
									</div>
								</div>
								<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnLayerClose1 layerPwFind_close">닫기</a>
							</div>
						</div>
					</div>
					<div className="popup_background" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} style={style_popup_background}></div>
				</>
			)
		} else {
			return (
				<>
					<div id="layerPwFind_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
						<div id="layerPwFind" data-popup-initialized="true" className="popup_content" style={style_popup_content}>
							<div className="layerBox">
								<div className="layerHd">
									<h3>비밀번호 찾기</h3>
								</div>
								<div className="layerCnt">
									<div className="layerCom">
										<h4>이메일</h4>
										<div className="box">
											<div className="inputSt1">
												<InputDelButton type="text" name="email" className="clearInput" placeholder="이메일을 입력해 주세요" onInput={this.handleInput} />
											</div>
										</div>
										<div className="btnCenter">
											<a href="#this" className={`btnTy2 ${onClassSend}`} onClick={this.handleSubmit}>확인</a>
										</div>
									</div>
								</div>
								<a href="#this" className="txtHide btnLayerClose1 layerPwFind_close" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }}>닫기</a>
							</div>
						</div>
					</div>
					<div className="popup_background" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} style={style_popup_background}></div>
				</>
			)
		}
	}
}

export default withRouter(FindPassword)